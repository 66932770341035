import React, { useEffect, useRef, useState } from 'react';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import TextField from '@mui/material/TextField';
import Button from 'react-bootstrap/Button';
import ClearModal from './ClearModal';

const NewUnitCostForm = props => {
    const [isEditing, setIsEditing] = useState(false);
    const [clearPending, setClearPending] = useState(false);

    const tomorrow = dayjs().add(1, 'day').startOf('day');

    var init = useRef(true);

    const [inputData, setInputData] = useState({
        time: {value: null, error: ''},
        water: {value: '', error: ''},
        electricity: {value: '', error: ''},
        gas: {value: '', error: ''},
    });

    useEffect(() => {
        //deep copy to prevent error messages propagating to props
        setInputData({
            time: { ...props.updatedUnitCosts.time },
            water: { ...props.updatedUnitCosts.water },
            electricity: { ...props.updatedUnitCosts.electricity },
            gas: { ...props.updatedUnitCosts.gas }
        });
    }, [props.updatedUnitCosts]);

    const floatRegex = /^(-?\d*\.?\d*)?$/;

    const updateFormData = (value, field) => {
        if(!floatRegex.test(value)) return;

        setInputData(prev => ({
            ...prev,
            [`${field}`]: {
                value: value,
                error: prev[`${field}`].error
            }
        }));
    }

    const validateInputs = () => {
        console.log(inputData)
        let isValid = true;
        let inputCopy = JSON.parse(JSON.stringify(inputData));

        if (inputCopy.time.value && !(inputCopy.time.value instanceof dayjs)) {
            inputCopy.time.value = dayjs(inputCopy.time.value);  //force it back to dayjs object
        }

        if(!inputCopy.time.value){
            inputCopy.time.error = 'Required';
            isValid = false;
        }else inputCopy.time.error = '';

        if(inputCopy.water.value.toString().trim() === ''){
            inputCopy.water.error = 'Required';
            isValid = false;
        }else if(parseFloat(inputCopy.water.value) <= 0){
            inputCopy.water.error = 'Must be > 0';
            isValid = false;
        }else inputCopy.water.error = '';

        if(inputCopy.electricity.value.toString().trim() === ''){
            inputCopy.electricity.error = 'Required';
            isValid = false;
        }else if(parseFloat(inputCopy.electricity.value) <= 0){
            inputCopy.electricity.error = 'Must be > 0';
            isValid = false;
        }else inputCopy.electricity.error = '';

        if(inputCopy.gas.value.toString().trim() === ''){
            inputCopy.gas.error = 'Required';
            isValid = false;
        }else if(parseFloat(inputCopy.gas.value) <= 0){
            inputCopy.gas.error = 'Must be > 0';
            isValid = false;
        }else inputCopy.gas.error = '';

        setInputData(inputCopy);

        return isValid;
    }

    const clearForm = async () => {
        props.clearUpcomingUnitCosts();

        setInputData({
            time: {value: null, error: ''},
            water: {value: '', error: ''},
            electricity: {value: '', error: ''},
            gas: {value: '', error: ''},
        });

        setClearPending(false);
    }

    return (
        <div style={{height:'fit-content', width:'30vw', backgroundColor:'white', padding:'1vh 1vw', borderRadius:'1%', marginBottom:'2vh', boxShadow:'2px 6px 12px rgb(164, 164, 164)'}}>
            <ClearModal clearPending={clearPending} setClearPending={setClearPending} clearForm={clearForm}/>

            <h4>Upcoming unit cost changes</h4>

            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={['DatePicker']}>
                    <DatePicker 
                        disabled={!isEditing} 
                        label="Takes effect on"
                        shouldDisableDate={date => date.isBefore(tomorrow)}
                        value={inputData.time.value}
                        onChange={value => {
                            setInputData(prev => ({
                                ...prev,
                                ['time']: {
                                    value,
                                    error: ''
                                }
                            }));
                            // setInputData()
                            // const unixSeconds = value.startOf('day').unix();
                            // console.log(unixSeconds)
                        }}
                    />
                    {inputData.time.error !== '' && <small style={{color:'red'}}>{inputData.time.error}</small>}
                </DemoContainer>
            </LocalizationProvider>

            <br/>

            <TextField
                fullWidth
                autoComplete='off'
                disabled={!isEditing}
                label={<span>Water unit cost ($/m<sup>3</sup>)</span>}
                id='site-modal-name'
                value={inputData.water.value}
                onChange={e => updateFormData(e.target.value, 'water')}
                error={inputData.water.error !== ''}
                helperText={inputData.water.error}
            />

            <br/>
            <br/>

            <TextField
                fullWidth
                autoComplete='off'
                disabled={!isEditing}
                label={<span>Electricity unit cost ($/kWh)</span>}
                id='site-modal-name'
                value={inputData.electricity.value}
                onChange={e => updateFormData(e.target.value, 'electricity')}
                error={inputData.electricity.error !== ''}
                helperText={inputData.electricity.error}
            />

            <br/>
            <br/>

            <TextField
                fullWidth
                autoComplete='off'
                disabled={!isEditing}
                label={<span>Gas unit cost ($/m<sup>3</sup>)</span>}
                id='site-modal-name'
                value={inputData.gas.value}
                onChange={e => updateFormData(e.target.value, 'gas')}
                error={inputData.gas.error !== ''}
                helperText={inputData.gas.error}
            />

            <br/>
            <br/>

            <div style={{display:'flex', flexDirection:'row'}}>
                <Button style={isEditing ? {border:'none'} : {backgroundColor:'rgb(8,114,182)', border:'none'}} variant={isEditing ? 'secondary' : 'primary'} onClick={() => {
                    if(isEditing){
                        setInputData({...props.updatedUnitCosts});
                    }

                    setIsEditing(prev => !prev);
                }}>
                    {isEditing ? 'Cancel' : 'Edit'}
                </Button>

                <div style={{flex:'1'}}></div>

                <Button style={{backgroundColor: isEditing ? 'rgb(8,114,182)' : '#DC4C64', border:'none'}} onClick={async () => {
                    if(isEditing){
                        if(!validateInputs()) return;

                        let saveSubmit = await props.saveUpcomingUnitCosts(inputData);
                        
                        setIsEditing(false);
                    }else{
                        setClearPending(true);
                    }
                }}>
                    {isEditing ? 'Save' : 'Clear'}
                </Button>
            </div>
        </div>
    );
}

export default NewUnitCostForm;