import React, { useEffect, useRef, useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import useAxiosProtect from '../../../hooks/useAxiosProtect';
import IconButton from '@mui/material/IconButton';
import NotificationAddIcon from '@mui/icons-material/NotificationAdd';
import RuleIcon from '@mui/icons-material/Rule';
import CommentSection from './CommentSection';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import Tooltip from '@mui/material/Tooltip';
import HelpCenter from '@mui/icons-material/HelpCenter';
import MenuItem from '@mui/material/MenuItem';
import Skeleton from '@mui/material/Skeleton';
import WarningIcon from '@mui/icons-material/Warning';
import VariableSavings from './graphs/VariableSavings';
import RulesModal from './modals/RulesModal';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import TotalSavings from './graphs/TotalSavings';
import jwtDecode from 'jwt-decode';
import useAuth from '../../../hooks/useAuth';
import TextField from '@mui/material/TextField';
import AlertsModal from './modals/AlertsModal';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import WavesIcon from '@mui/icons-material/Waves';
import ElectricBoltIcon from '@mui/icons-material/ElectricBolt';
import LocalGasStationIcon from '@mui/icons-material/LocalGasStation';
import PropertiesTab from './PropertiesTab';
import EventsContainer from '../../events/EventsContainer';

const createData = (name, value, annualValue=null) => {
    return { name, value, annualValue };
}

const OpportunityPage = props => {
    var init = useRef(true);
    var pendingTimeframeChange = useRef(false);
    const axiosProtect = useAxiosProtect();

    const [isFixed, setIsFixed] = useState(null);

    const {auth, persist} = useAuth();
    const [isESUser, setIsESUser] = useState(false);

    const [opportunity, setOpportunity] = useState(null);
    const [projectionRows, setProjectionRows] = useState([]);
    const [savingsRows, setSavingsRows] = useState([]);
    const [projectStatus,setProjectStatus] = useState('inactive');
    const [timeframe, setTimeframe] = useState('30d');

    const oppID = window.location.href.split('/')[window.location.href.split('/').length - 1];

    const [rulesModalOpen, setRulesModalOpen] = useState(false);
    const [alertsModalOpen, setAlertsModalOpen] = useState(false);
    const [tabValue, setTabValue] = useState('table');
    const [savingsData, setSavingsData] = useState(null);

    const [activeMetric, setActiveMetric] = useState({
        value: '',
        hasWater: false,
        hasElectricity: false,
        hasGas: false,
        hasGHG: true
    })

    useEffect(() => {
        if(!init.current) return;
        init.current = false;

        if(auth?.accessToken){
            let tokenObj = jwtDecode(auth.accessToken);
            setIsESUser(tokenObj.role == process.env.REACT_APP_ES_ADMIN || tokenObj.role == process.env.REACT_APP_ES_USER)
        }

        axiosProtect.get(`/opp/opportunities/opp-id?opp_id=${oppID}`).then(response => {
            let oppCopy = null;

            if(response.status === 200){

                setOpportunity(response.data.opportunity);
                setActiveMetric(prev => ({
                    ...prev,
                    value: response.data.opportunity.has_water ? 'water' : response.data.opportunity.has_electricity ? 'electricity' : response.data.opportunity.has_gas ? 'gas' : activeMetric.value,
                    hasWater: response.data.opportunity.has_water,
                    hasElectricity: response.data.opportunity.has_electricity,
                    hasGas: response.data.opportunity.has_gas,
                }));
                setProjectStatus(response.data.opportunity.project_progress_status)
                populateProjectionsTable(response.data.opportunity);
                setIsFixed(response.data.opportunity.calc_type === 'fixed')
                
                oppCopy = response.data.opportunity;

                axiosProtect.get(`/opp/opportunities/opp-timeseries?opp_id=${oppID}&start=${timeframe}`).then(response => {
                    if(response.status !== 200) return;
        
                    populateSavingsTable(oppCopy, response.data.sums);
                    setSavingsData(response.data.data);
        
                }).catch(error => {
                    console.log(error);
                });
            }
        }).catch(error => {
            console.log(error);
        });
    }, []);

    useEffect(() => {
        if(!pendingTimeframeChange.current) return;
        pendingTimeframeChange.current = false;

        axiosProtect.get(`/opp/opportunities/opp-timeseries?opp_id=${oppID}&start=${timeframe}`).then(response => {
            if(response.status !== 200) return;

            populateSavingsTable(opportunity, response.data.sums);
            setSavingsData(response.data.data);

        }).catch(error => {
            console.log(error);
        });

    }, [timeframe]);

    const populateProjectionsTable = opp => {
        let ghgReduction = (opp.has_water ? opp.water_savings_m3_year * opp.water_kg_co2_m3 / 1000 : 0) +
            (opp.has_electricity ? opp.electricity_savings_kwh_year * opp.electricity_kg_co2_kwh / 1000 : 0) + 
            (opp.has_gas ? opp.gas_savings_units_year * opp.gas_kg_co2_btu * opp.gas_btu_per_unit / 1000 : 0);
        
        let costSavings = (opp.has_water ? opp.water_savings_m3_year * opp.water_unit_cost_m3 : 0) + 
            (opp.has_electricity ? opp.electricity_savings_kwh_year * opp.electricity_unit_cost_kwh : 0) + 
            (opp.has_gas ? opp.gas_savings_units_year * opp.gas_unit_cost : 0);

        setProjectionRows(
            [
                createData('Water savings', `${opp.has_water ? `${Math.round(opp.water_savings_m3_year).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} m3` : 'N/A'}`),
                createData('Electricity savings', `${opp.has_electricity ? `${Math.round(opp.electricity_savings_kwh_year).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} kWh` : 'N/A'}`),
                createData(
                    `${opp.has_gas ? opp.gas_type.substring(0,1).toUpperCase() + opp.gas_type.substring(1, opp.gas_type.length) : 'Gas'} savings`, 
                    `${opp.has_gas ? `${Math.round(opp.gas_savings_units_year).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} ${opp.gas_units === 'l' ? 'L' : 'm3'}` : 'N/A'}`
                ),
                createData('GHG reduction', `${Number(Math.round(100 * ghgReduction) / 100).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} MT CO2e`),
                createData('Financial savings', `$${Math.round(costSavings).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`),
                createData('Project cost', `$${Math.round(opp.project_cost).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`),
                createData('Payback', `${Number(Math.round(100 * opp.payback_years) / 100).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} years`),
            ]
        );
    }

    const isLeapYear = () => {
        let year = new Date().getFullYear();

        return (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;
    }

    const daysSinceNewYear = () => {
        let now = new Date();
        let newYear = new Date(now.getFullYear(), 0, 1);

        let deltaMs = now - newYear;
        return Math.floor(deltaMs / (1000 * 60 * 60 * 24));
    }

    const daysSinceISOTimestamp = timestamp => {
        let dateFromISO = new Date(timestamp);
        let currentDate = new Date();
        let timeDiff = currentDate - dateFromISO;
        return Math.round(timeDiff / (1000 * 60 * 60 * 24));
    }

    const computeExtrapolationFactor = dateCreated => {
        let daysInSet = 365 + (isLeapYear() ? 1 : 0); //timeframe = 1y

        if(timeframe === 'ytd') daysInSet = daysSinceNewYear();
        else if(timeframe === '30d') daysInSet = '30';
        else if(timeframe === 'alltime') daysInSet = daysSinceISOTimestamp(dateCreated);

        return (365 + (isLeapYear() ? 1 : 0)) / daysInSet
    }

    const populateSavingsTable = (opp, data) => {
        let extrapolationFactor = computeExtrapolationFactor(opp.date_created);

        setSavingsRows(
            [
                createData(
                    'Water savings', 
                    `${opp.has_water ? `${Math.round(data.water).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} m3` : 'N/A'}`,
                    `${opp.has_water ? `${Math.round(data.water * extrapolationFactor).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} m3/yr` : 'N/A'}`,
                ),
                createData(
                    'Electricity savings', 
                    `${opp.has_electricity ? `${Math.round(data.electricity).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} kWh` : 'N/A'}`,
                    `${opp.has_electricity ? `${Math.round(data.electricity * extrapolationFactor).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} kWh/yr` : 'N/A'}`
                ),
                createData(
                    `${opp.has_gas ? opp.gas_type.substring(0,1).toUpperCase() + opp.gas_type.substring(1, opp.gas_type.length) : 'Gas'} savings`, 
                    `${opp.has_gas ? `${Math.round(data.gas).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} ${opp.gas_units === 'l' ? 'L' : 'm3'}` : 'N/A'}`,
                    `${opp.has_gas ? `${Math.round(data.gas * extrapolationFactor).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} ${opp.gas_units === 'l' ? 'L/yr' : 'm3/yr'}` : 'N/A'}`,
                ),
                createData(
                    'GHG reduction', 
                    `${Number(Math.round(100 * data.ghg / 1000) / 100).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} MT CO2e`,
                    `${Number(Math.round(100 * data.ghg * extrapolationFactor / 1000) / 100).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} MT CO2e/yr`,
                ),
                createData(
                    'Financial savings', 
                    `$${Math.round(data.cost).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`,
                    `$${Math.round(data.cost * extrapolationFactor).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`,
                ),
            ]
        )
    }

    const updateProjectStatus = async status => {
        try{
            // let response = await axiosProtect.put('/opp/opportunities/edit-opp', {
            //     id: oppID,
            //     fields: [
            //         {
            //             column: 'project_progress_status',
            //             value: status
            //         }
            //     ]
            // });

            let response = await axiosProtect.put(`/opp/opportunities/edit-opp?opp_id=${oppID}&statusChange=true`, {
                project_progress_status: status
            });

            setProjectStatus(status);
        }catch(error){
            console.log(error);
            return false;
        }
    }

    return(
        <>
        {rulesModalOpen && <RulesModal rulesModalOpen={rulesModalOpen} setRulesModalOpen={setRulesModalOpen} />}
        {alertsModalOpen && <AlertsModal alertsModalOpen={alertsModalOpen} setAlertsModalOpen={setAlertsModalOpen} opportunity={opportunity} />}

        <div style={{height:`${props.ctrHeight * 0.94}px`}}>
            <div style={{display:'flex', flexDirection:'row'}}>
            <h4 style={{fontSize:'1.25rem'}}>
                {opportunity?.title} 
                <span style={{marginLeft:'1vw'}}>
                    <Tooltip title={`Alerts`}>
                        <IconButton style={{color:'rgb(8,114,182)'}} onClick={() => setAlertsModalOpen(true)}>
                            <NotificationAddIcon/>
                        </IconButton>
                    </Tooltip>
                </span>
                {isESUser && <span style={{marginLeft:'1vw'}}>
                    <Tooltip title={`Savings criteria`}>
                        <IconButton onClick={() => setRulesModalOpen(true)}>
                            <RuleIcon style={{color:'rgb(0,177,168)'}}/>
                        </IconButton>
                    </Tooltip>
                </span>}
            </h4>
                <div>
                    {
                        isESUser ? 
                        <FormControl variant="standard" sx={{ my: -1.5, mx: 4, minWidth: 120 }}>
                            <InputLabel>Status</InputLabel>
                            <Select
                                label="Status"
                                value={projectStatus}
                                onChange={e => {
                                    if(e.target.value === projectStatus) return;
                                    updateProjectStatus(e.target.value);
                                }}
                            >
                                <MenuItem value='inactive'>Inactive</MenuItem>
                                <MenuItem value='in progress'>In progress</MenuItem>
                                <MenuItem value='not doing'>Not doing</MenuItem>
                                <MenuItem value='on hold'>On hold</MenuItem>
                                <MenuItem value='complete'>Complete</MenuItem>
                            </Select>
                        </FormControl> 
                        :
                        <TextField
                            value={projectStatus}
                            sx={{ my: -1.5, mx: 4, minWidth: 120}}
                            label="Status"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            aria-readonly
                        />
                    }
                </div>

                <Tooltip title={`Change the project status by selecting an option from the dropdown menu. Only 'Complete' opportunities will actively track savings.`}>
                    <IconButton sx={{ my: -1.5, mx: 1, "&.MuiButtonBase-root:hover": {bgcolor: "transparent"}}} disableTouchRipple>
                        <HelpCenter/>
                    </IconButton>
                </Tooltip>

                <Tooltip title={`Actively computed opportunities quantify savings based on real-time logging data. Passive computation interpolates daily savings rates based on annual projections.`}>
                    <IconButton sx={{ my: -1.5, mx: 1, "&.MuiButtonBase-root:hover": {bgcolor: "transparent"}}} disableTouchRipple>
                        <p style={{margin:'0', fontSize:'1rem', color:''}}>Savings computed {opportunity?.calc_type === 'fixed' ? 'passively' : 'actively'}</p>
                    </IconButton>
                </Tooltip>

                <FormControl variant="standard" sx={{ my: -1.5, mx: 4, minWidth: 120 }}>
                    <InputLabel>Timeframe</InputLabel>
                    <Select
                        label="Timeframe"
                        value={timeframe}
                        onChange={e => {
                            if(e.target.value === timeframe) return;
                            pendingTimeframeChange.current = true;
                            setTimeframe(e.target.value);
                        }}
                    >
                        <MenuItem value='30d'>30 days</MenuItem>
                        <MenuItem value='ytd'>YTD</MenuItem>
                        <MenuItem value='1y'>1Y</MenuItem>
                        <MenuItem value='all-time'>All time</MenuItem>
                    </Select>
                </FormControl> 
            </div>

            <div style={{display:'flex', flexDirection:'row', height:'100%'}}>
                <div style={{flex:'71'}}>
                    <div style={{backgroundColor: 'white', height:'46.5%', boxShadow:'2px 6px 12px rgb(164, 164, 164)', borderRadius:'1%'}}>
                        {
                            (opportunity == null || savingsData == null) ?
                            <div style={{width:'100%', height:'100%'}}>
                                <Skeleton variant="rounded" style={{height:'100%'}} animation="wave"/>
                            </div>
                            :
                            projectStatus !== 'complete' ? 
                            <div id="raw-data-graph-error-msg-container" style={{height:'100%'}}>
                                <WarningIcon style={{height:'15%', width:'auto', marginTop:'-4%', color:'red'}}/>
                                <br/>
                                <h4 style={{textAlign:'center'}}>This is opportunity is not marked as complete. Savings will be plotted here once it is active.</h4>
                            </div>
                            :
                            <div style={{width:'100%', height:'100%', position:'relative'}}>
                                <div style={{position:'absolute', top:'25%', right:'1%', zIndex:`${(!rulesModalOpen && !alertsModalOpen) ? 99999 : 1}`}}>
                                    <ToggleButtonGroup
                                        value={activeMetric.value}
                                        orientation="vertical"
                                        exclusive
                                        onChange={(_, newValue) => {
                                            if(newValue == null) return;
                                            setActiveMetric(prev => ({
                                                ...prev,
                                                value: newValue
                                            }));
                                        }}
                                        aria-label="text alignment"
                                        size='small'
                                    >
                                        <ToggleButton value="water" aria-label="centered" disabled={!activeMetric.hasWater}>
                                            <Tooltip title='Water' placement='right'>
                                                <WavesIcon/>
                                            </Tooltip>
                                        </ToggleButton>
                                        <ToggleButton value="electricity" aria-label="centered" disabled={!activeMetric.hasElectricity}>
                                            <Tooltip title='Electricity' placement='right'>
                                                <ElectricBoltIcon/>
                                            </Tooltip>
                                        </ToggleButton>
                                        <ToggleButton value="gas" aria-label="centered" disabled={!activeMetric.hasGas}>
                                            <Tooltip title='Gas' placement='right'>
                                                <LocalGasStationIcon/>
                                            </Tooltip>
                                        </ToggleButton>
                                    </ToggleButtonGroup>
                                </div>

                                <VariableSavings savingsData={savingsData} activeMetric={activeMetric}/>
                            </div>
                        }
                    </div>

                    <div style={{height:'2%'}}></div>

                    <div style={{flex:'40', height:'46.5%', backgroundColor:'white', boxShadow:'2px 6px 12px rgb(164, 164, 164)', borderRadius:'1%'}}>
                        {
                            (opportunity == null || savingsData == null) ?
                            <div style={{width:'100%', height:'100%'}}>
                                <Skeleton variant="rounded" style={{height:'100%'}} animation="wave"/>
                            </div>
                            :
                            projectStatus !== 'complete' ? 
                            <div id="raw-data-graph-error-msg-container" style={{height:'100%'}}>
                                <WarningIcon style={{height:'15%', width:'auto', marginTop:'-4%', color:'red'}}/>
                                <br/>
                                <h4 style={{textAlign:'center'}}>This is opportunity is not marked as complete. Savings will be plotted here once it is active.</h4>
                            </div>
                            :
                            <div style={{width:'100%', height:'100%'}}>
                                <TotalSavings savingsData={savingsData}/>
                            </div>
                        }
                    </div>
                </div>

                <div style={{flex:'3'}}></div>

                <div style={{flex:'26', overflowY:'scroll', scrollbarWidth: 'none', msOverflowStyle: 'none',}}>
                    <Box sx={{width: '100%', backgroundColor:'rgba(255,255,255,0.4)'}}>
                        <Tabs
                            value={tabValue}
                            onChange={(e, v) => {
                                setTabValue(v)
                            }}
                            sx={{
                                '& .MuiTabs-indicator': {
                                    backgroundColor: 'rgb(0,177,168)'
                                },
                                '& .MuiTab-textColorSecondary.Mui-selected': {
                                    color: 'rgb(0,177,168)'
                                },
                                '& .MuiTab-textColorSecondary': {
                                    color: 'rgb(8,114,182)'
                                },
                            }}
                            aria-label="secondary tabs example"
                        >
                            <Tab value="table" label="Overview" />
                            <Tab value="comments" label="Comments" />
                            <Tab value="tasks" label="Tasks" />
                            {isESUser && <Tab disabled={opportunity == null} value="properties" label="Properties" />}
                        </Tabs>
                    </Box>

                    {
                        tabValue === 'table' ?
                        <>
                        <Table sx={{width: '100%', backgroundColor:'white', boxShadow:'2px 6px 12px rgb(164, 164, 164)', borderRadius:'1%'}} size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell colSpan={2}>
                                        <strong>Annual projected savings rate</strong>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                            {projectionRows.map((row) => (
                                <TableRow
                                key={row.name}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                <TableCell component="tr" scope="row">
                                    {row.name}
                                </TableCell>
                                <TableCell align="right">{row.value}</TableCell>
                                </TableRow>
                            ))}
                            </TableBody>
                        </Table>

                        <br/>

                        <Table sx={{ width: '100%', backgroundColor:'white',  boxShadow:'2px 6px 12px rgb(164, 164, 164)', borderRadius:'1%'}} size="small">
                            <TableHead>
                                <TableRow>
                                    {/* <TableCell></TableCell> */}
                                    <TableCell colSpan={2}>
                                        <strong>Savings realized to date</strong>
                                    </TableCell>
                                    <TableCell>
                                        <strong>Savings realized (as predicted annual rate)</strong>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                            {savingsRows.map((row) => (
                                <TableRow
                                    key={row.name}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell component="tr" scope="row">
                                        {row.name}
                                    </TableCell>
                                    <TableCell align="right">{row.value}</TableCell>
                                    <TableCell align="right">{row.annualValue}</TableCell>
                                </TableRow>
                            ))}
                            </TableBody>
                        </Table>
                        </>
                        : tabValue === 'comments' ?
                        <>
                        <CommentSection/>
                        </>
                        : tabValue === 'tasks' ?
                        <>
                        <EventsContainer oppView={true}/>
                        </>
                        :
                        <>
                        <PropertiesTab opportunity={opportunity} setOpportunity={setOpportunity} oppID={oppID} setTabValue={setTabValue}/>
                        </>
                    }
                </div>
            </div>
        </div>
        </>
    )
}

export default OpportunityPage;