import React, { useEffect, useRef, useState} from "react";
// import GHGModal from './GHGModal'
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Paper from '@mui/material/Paper';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import gas_types from '../../../example_data/dropdowns/clientModal/gasTypes';
import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import useAxiosProtect from "../../../hooks/useAxiosProtect";
import GHGModal from "./GHGModal";

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const GHGFactors = props => {
    const [containerHeight, setContainerHeight] = useState('95vh');

    const [modalState, setModalState] = useState('');
    const [serverError, setServerError] = useState('');
    const [factorRegionID, setFactorRegionID] = useState(null);
    const [factorToEdit, setFactorToEdit] = useState(null);
    const [factorToDelete, setFactorToDelete] = useState(null);

    const [availableCountries, setAvailableCountries] = useState([]);
    const [selectedCountry, setSelectedCountry] = useState('Canada');

    const [selectedCountryFactors, setSelectedCountryFactors] = useState([]);

    const [snackbarData, setSnackbarData] = useState({
        open: false,
        message: '',
        severity: 'success'
    });

    var init = useRef(true);

    const axiosProtect = useAxiosProtect();

    const closeToast = (event, reason) => {
        if (reason === 'clickaway') return;
    
        setSnackbarData(prev => ({
            ...prev,
            ['open']: false
        }))
    }
    
    useEffect(() => {
        const updateContainerHeight = () => {
            let navbar = document.querySelector('.navbar');
            let breadcrumbNav = document.getElementsByClassName('col s12 es-breadcrumb')[0];
  
            let navbarHeight = navbar.offsetHeight + breadcrumbNav.offsetHeight;
            let windowHeight = window.innerHeight;
            let remainingHeight = windowHeight - navbarHeight;
  
            setContainerHeight(`${remainingHeight}px`);
        };
      
        updateContainerHeight();
    
        window.addEventListener('resize', updateContainerHeight);
    
        return () => {
            window.removeEventListener('resize', updateContainerHeight);
        };
    }, []);

    useEffect(() => {
        if(!init.current) return;
        init.current = false;

        axiosProtect.get(`/ghg/ghg-factors/jurisdictions`).then(response => {
            let countries = Object.keys(response.data.jurisdictions);
            countries.sort((a, b) => a.localeCompare(b));

            setAvailableCountries(countries);
            setSelectedCountry('Canada');

        }).catch(error => {
            console.log(error);
        });
    }, []);

    useEffect(() => {
        axiosProtect.get(`/ghg/ghg-factors/emissions-factors?country=${selectedCountry}`).then(response => {
            setSelectedCountryFactors(response.data.territories);
        }).catch(error => {

        });
    }, [selectedCountry]);

    useEffect(() => {
        if(factorToEdit == null) setModalState('');
        else setModalState('edit-factor');
    }, [factorToEdit]);

    useEffect(() => {
        if(factorToDelete == null) setModalState('');
        else setModalState('delete-factor');
    }, [factorToDelete]);

    const submitForm = data => {
        let payload = {};

        if(modalState === 'region'){
            payload.territory = data.region.trim();
            payload.country = data.country.trim();

        }else if(modalState === 'factor' || modalState === 'edit-factor'){
            payload.water_kg_co2_m3 = parseFloat(data.water_kg_co2_m3);
            payload.elec_kg_co2_kwh = parseFloat(data.elec_kg_co2_kwh);
            payload.gas_kg_co2_btu = parseFloat(data.gas_kg_co2_btu);
            payload.year = parseInt(data.year);
            payload.jurisdiction_id = parseFloat(parseInt(factorRegionID));
        }

        if(modalState === 'edit-factor'){
            payload.id = factorToEdit.id;

            axiosProtect.put('/ghg/ghg-factors/factor', payload).then(response => {
                let factorsCopy = [...selectedCountryFactors];
                
                factorsCopy[data.territoryIndex].factors[data.factorIndex] = data;
                setSelectedCountryFactors(factorsCopy);

                setFactorToEdit(null);

                setSnackbarData(prev => ({
                    ...prev,
                    ['open']: true,
                    ['severity']: 'success',
                    ['message']: 'Factor updated'
                }));

                setServerError('');
                
            }).catch(error => {
                setServerError(error.response.data.error)
            });

        }else{
            axiosProtect.post(`/ghg/ghg-factors/${modalState}`, payload).then(response => {
                if(modalState === 'region'){
                    let region = response.data.region;
    
                    if(region.country === selectedCountry){
                        let regionObject = {
                            expanded: false,
                            id: region.id,
                            territory: region.territory,
                            factors: []
                        }
    
                        let factorsCopy = [...selectedCountryFactors];
                        let insertIndex = factorsCopy.findIndex(reg => reg.territory.localeCompare(regionObject.territory) > 0);
    
                        if(insertIndex === -1) factorsCopy.push(regionObject);
                        else factorsCopy.splice(insertIndex, 0, regionObject);
    
                        setSelectedCountryFactors(factorsCopy);
    
                    }else{
                        if(availableCountries.includes(region.country)){
                            setSelectedCountry(region.country);
                        
                        }else{
                            let countriesCopy = [...availableCountries];
                            let insertIndex = countriesCopy.findIndex(country => country > region.country);
    
                            if(insertIndex === -1) countriesCopy.push(region.country);
                            else countriesCopy.splice(insertIndex, 0, region.country);
                            
                            setAvailableCountries(countriesCopy);
                            setSelectedCountry(region.country);
                        }
                    }
    
                }else if(modalState === 'factor'){
                    let factor = response.data.factors;
                    let jID = factor.jurisdiction_id;
    
                    let factorsCopy = [...selectedCountryFactors];
    
                    for(let i = 0;i<factorsCopy.length;++i){
                        if(factorsCopy[i].id === jID){
                            let insertIndex = factorsCopy[i].factors.findIndex(fct => fct.year < factor.year);
    
                            if(insertIndex === -1) factorsCopy[i].factors.push(factor);
                            else factorsCopy[i].factors.splice(insertIndex, 0, factor);
    
                            setSelectedCountryFactors(factorsCopy);
                            break;
                        }
                    }
                }
    
                setModalState('');

                setSnackbarData(prev => ({
                    ...prev,
                    ['open']: true,
                    ['severity']: 'success',
                    ['message']: `${modalState === 'factor' ? 'Factor set' : 'Region'} created`
                }));

                setServerError('');
    
            }).catch(error => {
                setServerError(error.response.data.error);
            });
        }
    }

    const deleteFactor = () => {
        axiosProtect.delete(`/ghg/ghg-factors?id=${factorToDelete.id}`).then(response => {
            let factorCopy = [...selectedCountryFactors];

            factorCopy[factorToDelete.territoryIndex].factors.splice(factorToDelete.factorIndex, 1);
            setSelectedCountryFactors(factorCopy);
    
            setFactorToDelete(null);

            setSnackbarData(prev => ({
                ...prev,
                ['open']: true,
                ['severity']: 'success',
                ['message']: 'Factor deleted'
            }));

            setServerError('');

        }).catch(error => {
            setFactorToDelete(null);

            setSnackbarData(prev => ({
                ...prev,
                ['open']: true,
                ['severity']: 'error',
                ['message']: 'Could not delete factor'
            }));

            setServerError('');
        });
    }

    const updateAccordionState = index => {
        let factorCopy = [...selectedCountryFactors];
        factorCopy[index].expanded = !factorCopy[index].expanded;
        setSelectedCountryFactors(factorCopy);
    }

    return (
        <div style={{padding:`1% 2%`, backgroundColor:'#f5f5f5', height: containerHeight, overflowX:'hidden'}}>

            {
                modalState !== '' && 
                <GHGModal modalState={modalState} setModalState={setModalState} submitForm={submitForm} 
                    factorToEdit={factorToEdit} setFactorToEdit={setFactorToEdit} 
                    factorToDelete={factorToDelete} setFactorToDelete={setFactorToDelete} 
                    serverError={serverError} setServerError={setServerError}
                    deleteFactor={deleteFactor}
                />
            }

            <div style={{display:'flex',flexDirection:'row', width:'100%'}}>
                <Button
                    onClick={() => setModalState('region')}
                >
                    Add region
                </Button>

                <div style={{flex:'4'}}></div>

                <Autocomplete
                    style={{flex:'1'}}
                    disableClearable
                    options={availableCountries}
                    value={selectedCountry}
                    onChange={(_, option) => {
                        setSelectedCountry(option);
                    }}
                    renderOption={(props, option) => (
                        <Box component="li" {...props}>
                            <span>{option}</span>
                        </Box>
                    )}
                    renderInput={(params) => <TextField style={{width:'100%'}} {...params} inputProps={{...params.inputProps}} label="Select country" />}
                />
            </div>

            <br/>

            <div>
                {
                    selectedCountryFactors.map((factor, i) => (
                        <Accordion expanded={factor.expanded} onChange={() => updateAccordionState(i)} style={{backgroundColor:'rgba(0,0,0,0)', width:'95vw'}} key={i}>
                            <AccordionSummary
                                style={{backgroundColor:`rgba(255,255,255,${true > 0 ? 1 : 0})`}}
                                expandIcon={<ExpandMoreIcon style={{backgroundColor: true ? 'rgb(8,114,182)' : 'white', borderRadius:'50%', color: true ? 'white' : 'rgb(78,79,83)' }}/>}
                                aria-controls="panel4bh-content"
                                id="panel4bh-header"
                            >
                                <Typography sx={{ width: '50%', flexShrink: 0 }} variant='h4' fontSize={'1.25rem'}>{factor.territory}</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <div style={{ width:'fit-content'}}>
                                    <Button onClick={() => {
                                        setFactorRegionID(factor.id);
                                        setModalState('factor');
                                    }}>
                                        Add factor
                                    </Button>

                                    {
                                        factor.factors.length === 0 ? 
                                        <p>No factors</p>
                                        :
                                        <VerticalTimeline layout='1-column-left'>
                                            {
                                                factor.factors.map((factors, j) => (
                                                    <VerticalTimelineElement
                                                        className="vertical-timeline-element--work"
                                                        date={factors.year}
                                                        iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
                                                        // icon={<CommentIcon />}
                                                        position='right'
                                                        contentStyle={{padding:'4% 6% 0% 6%'}}
                                                        style={{width:'150%'}}
                                                    >
                                                        <TableContainer component={Paper}>
                                                            <Table aria-label="collapsible table">
                                                                <TableHead style={{backgroundColor:'rgb(3,117,176)'}}>
                                                                    <TableRow>
                                                                        <TableCell style={{color:'whitesmoke'}} align="center">Water</TableCell>
                                                                        <TableCell style={{color:'whitesmoke'}} align="center">Electricity</TableCell>
                                                                        <TableCell style={{color:'whitesmoke'}} align="center">Gas</TableCell>
                                                                    </TableRow>
                                                                </TableHead>
                                                                <TableBody>
                                                                    <TableRow>
                                                                        <TableCell align="center">{factors.water_kg_co2_m3} kg CO<sub>2</sub>e/m<sup>3</sup></TableCell>
                                                                        <TableCell align="center">{factors.elec_kg_co2_kwh} kg CO<sub>2</sub>e/kWh</TableCell>
                                                                        <TableCell align="center">{factors.gas_kg_co2_btu} kg CO<sub>2</sub>e/BTU</TableCell>
                                                                    </TableRow>
                                                                </TableBody>
                                                            </Table>
                                                        </TableContainer>

                                                        <div style={{display: 'flex', flexDirection:'row', marginTop:'1%'}}>
                                                            <IconButton onClick={() => {
                                                                let tempFactor = factors;
                                                                
                                                                tempFactor.waterError = '';
                                                                tempFactor.elecError = '';
                                                                tempFactor.gasError = '';
                                                                tempFactor.territoryIndex = i;
                                                                tempFactor.factorIndex = j;

                                                                setFactorToEdit(factors);
                                                            }}>
                                                                <EditTwoToneIcon style={{color:'rgb(0,177,168)'}}/>
                                                            </IconButton>

                                                            <div style={{width:'1vw'}}></div>

                                                            <IconButton onClick={() => {
                                                                let factorToDel = factors;
                                                                
                                                                factorToDel.territory = factor.territory;
                                                                factorToDel.territoryIndex = i;
                                                                factorToDel.factorIndex = j;

                                                                setFactorToDelete(factorToDel);
                                                            }}>
                                                                <DeleteTwoToneIcon style={{color:'red'}}/>
                                                            </IconButton>
                                                        </div>
                                                    </VerticalTimelineElement>
                                                ))
                                            }
                                        </VerticalTimeline>
                                    }
                                </div>
                            </AccordionDetails>
                        </Accordion>
                    ))
                }
            </div>

            <Snackbar anchorOrigin={{ vertical:'bottom', horizontal:'left' }} open={snackbarData.open} autoHideDuration={4000} onClose={closeToast}>
                <Alert onClose={closeToast} severity={snackbarData.severity} sx={{ width: '100%' }}>
                    {snackbarData.message}
                </Alert>
            </Snackbar>
        </div>
       
    )
}



export default GHGFactors;