import React from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

const ClearModal = props => {
    return(
        <Modal
            show={props.clearPending}
            backdrop="static"
            centered
        >
            <Modal.Header>
                <h4>Clear Unit Cost Changes?</h4>
            </Modal.Header>

            <Modal.Body>
                <p>This will clear the upcoming changes to the unit cost. It will remain unchanged until it is edited re-configured.</p>
            </Modal.Body>

            <Modal.Footer>
                <div style={{display:'flex', width:'100%'}}>
                    <Button style={{flex:'1'}} variant='danger' onClick={() => {
                        props.clearForm();
                    }}>
                        Confirm
                    </Button>

                    <div style={{flex:'4'}}></div>

                    <Button style={{flex:'1'}} variant="secondary" onClick={() => {
                        props.setClearPending(false);
                    }}>Cancel</Button>
                </div>
            </Modal.Footer>
        </Modal>
    );
}

export default ClearModal;